.buttons-enpreendedor {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
}

.buttons-enpreendedor a {
  border: 2px solid #424242;
  padding: 5px 16px;
  border-radius: 6px;
  font-weight: 600;
  background-color: #fff;
  box-shadow: 2px 2px 4px #ccc;
}

.buttons-enpreendedor a:hover {
  cursor: pointer;
  border: 2px solid #000080;
}

.section-title {
  padding: 3px 20px;
}
