.box_buttons {
  padding: 5px 12px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

.box_buttons h4 {
  max-width: 80vw;
  font-size: 1.15rem;
  color: #000080;
  word-wrap: break-word;
  font-weight: 600;
}

.box_buttons h4[id="Educação"] {
  color: #fff;
}

.back {
  background-image: url("../../public/imagens/A.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -999;
  bottom: 0;
  width: 100vw;
  height: 70vh;
  left: -10px;
}

.btn_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  padding: 10px;
}

.btns_container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px 45px;
  flex-wrap: wrap;
  padding: 5px 20px;
}
